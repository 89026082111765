<template>
  <div class="oops">
  <div class="container">
    <div class="oops__inner">
      <div class="oops__content">
        <h2 class="oops__title">404</h2>
        <p class="oops__text">
          Opps, something went wrong!
        </p>
        <a class="btn btn-secondary" href @click.prevent="$router.go(-2)">Go Back</a>
      </div>
    </div>
  </div>
</div>
</template>
<script>
export default {
  mounted() {
    document.body.classList.remove('page-layout');
    document.body.style.background = 'none';
  },
};
</script>
<style lang="scss" scoped>
</style>
